<template>
  <!-- 报价记录(bom) -->
  <div class="module-wrapper bom-quote-details-box" :style="{height:height}">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      box-class="bom-quote-details-box"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('BomMastLineSelect')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
      @dbclick="e => {$emit('dbl-click', e.rowData)}"
    >
      <div slot="header" class="v-table-toolbar" v-if="isShowHeader">
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="报价日期从"
          :border="true"
          size="mini"
          layout="left"
          width="100px"
          :clearable="true"
          inp-perc="100%"
          v-model="QuotedTimeByStart"
        ></znl-input>
        <span>到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="报价日期止"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="QuotedTimeByEnd"
        ></znl-input>
        <el-button type="text" class="underline font12" @click="setCurrentMonth">本月</el-button>

        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="请输入型号"
          size="mini"
          layout="left"
          width="120px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="model"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="请输入客户名称"
          size="mini"
          layout="left"
          width="180px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="customerName"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>

        <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>

        <znl-button
          style-type="mac"
          :btns="printznlbtns"
          :height="22"
          @click="onExport"
          tip="按搜索条件"
        >
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
      </div>
    </znl-table>

    <stk-out-edit
      :editvisible="stkOutEditVisible"
      v-if="stkOutEditVisible"
      :edit-type="editType"
      :autoAddNewRow="false"
      @confirm="stkOutEditVisible=false"
      @close="stkOutEditVisible=false"
    ></stk-out-edit>
    <stk-in-edit
      :editvisible="stkInEditVisible"
      v-if="stkInEditVisible"
      :edit-type="editType"
      :autoAddNewRow="false"
      @confirm="stkInEditVisible=false"
      @close="stkInEditVisible=false"
    ></stk-in-edit>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>
  </div>
</template>
<script>
const CONFIG = {
  configURL: "BomMastLineSelect/GetConfig",
  saveConfigURL: "BomMastLineSelect/SaveConfig",
  searchURL: "BomMastLineSelect/Search"
};
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import StkOutEdit from "@c_modules/Stk/StkOutEdit";
import StkInEdit from "@c_modules/Stk/StkInEdit";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import { exportData, erpTableSetHandler } from "@scripts/methods/common";
import { setTimeout } from "timers";

export default {
  name: "BomQuoteDetails",
  mixins: [getCommonDataMixin, exportData, erpTableSetHandler],
  config: CONFIG,
  components: {
    StkOutEdit,
    StkInEdit,
    ExportColumn
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    hideColumns: {
      type: Array,
      default: () => {
        return [
          "DscQuotePriceText",
          "LiChuangPriceText",
          "YunHanPriceText",
          "DigikeyPriceText",
          "BuyPrice",
          "BuyerQuoteTime",
          "BuyerName",
          "QuoteRemark",
          "BuyerRemark",
          "Remark"
        ];
      }
    },
    isShowOperateCol: {
      type: Boolean,
      default: true
    },
    isShowHeader: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: "100%"
    },
    afterGridInit: {
      // 表格初始化完成后调用
      type: Function
    }
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      loading: false,
      resourceList: [],
      fieldsFilter: {},
      stkOutEditVisible: false, // 新增发货单弹窗
      stkInEditVisible: false,
      isShowFiedsSearch: false,
      model: "", // 需要查询的型号
      customerName: "", // 需要查询的客户名称
      QuotedTimeByStart: "",
      QuotedTimeByEnd: "",
      printznlbtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      defaultConfig: {},
      setSaveConfigURL: "",
      currencyList: [],
      userList: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  async created() {
    // 币种
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
    // 员工列表
    this.userList = _.map(this.selectOptionsAllUserURL, function(obj) {
      return { key: obj.value, value: obj.value };
    });
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      this.loading = true;
      if (!config) {
        config = await this.isSaveStorage(
          "BomMastLineSelect",
          CONFIG.configURL
        );
      }
      this.resourceList = config.ResourceList.map(item => item.Code);
      let columns = config.ColumnConfigs;
      if (this.isShowOperateCol) {
        let tempConfig = [];
        if (this.hasRes("OnStkOut")) {
          tempConfig.push({
            width: 30,
            type: "button",
            title: "出",
            btnTxt: "出",
            visible: true,
            isDeal: false,
            click: row => {
              this.onAddStkOut(row);
            }
          });
        }
        if (this.hasRes("OnStkIn")) {
          tempConfig.push({
            width: 30,
            type: "button",
            title: "入",
            btnTxt: "入",
            visible: true,
            isDeal: false,
            click: row => {
              this.onAddStkIn(row);
            }
          });
        }
        columns = tempConfig.concat(columns);
      }
      let colIndex = _.findIndex(
        columns,
        item => item.BindField === "QuotedByName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.userList
        });
      }
      colIndex = _.findIndex(
        columns,
        item => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.currencyList
        });
      }

      let noSeeCustomer = this.getSpecialResourceByCode("NoSeeCustomer");
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");
      let noSeeProfit = this.getSpecialResourceByCode("NoSeeProfit");

      // 处理特殊权限
      let hideCols = ",CustomerName,Contact,Telephone,Email,Address,QQ,"; // 不看客户资料
      let hideBuyPrice = ",BuyPrice,"; // 供应商报价
      let hideRate = ",ProfitRate,"; // 不看毛利率

      _.remove(columns, item => {
        if (noSeeCustomer && hideCols.indexOf(item.BindField) > -1) {
          return true;
        }
        if (!viewBuyPrice && hideBuyPrice.indexOf(item.BindField) > -1) {
          return true;
        }
        if (noSeeProfit && hideRate.indexOf(item.BindField) > -1) {
          return true;
        }
      });

      this.columns = columns;
      this.defaultConfig = config;
      this.pageIndex = 1;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      setTimeout(() => {
        this.$refs.table.init();
        this.afterGridInit && this.afterGridInit();
      }, 100);

      if (this.initData) {
        await this.onBindData();
      }
      this.loading = false;
      this.$emit("page-loading", false);
    },

    async onHeadSearch(param = {}) {
      this.fieldsFilter.MakeYear = param.MakeYear || "";
      this.fieldsFilter.Brand = param.Brand || "";
      this.fieldsFilter.Packaging = param.Packaging || "";
      this.fieldsFilter.Model = param.Model;
      this.fieldsFilter.CustomerName = param.CustomerName;
      await this.onBindData(1);
    },

    onSearch(params) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(1);
    },
    async onBindData(pageIndex = 1) {
      let data = this.getSearchWhere(pageIndex);
      this.loading = true;
      return await this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
        this.$emit("page-loading", false);
      });
    },
    getSearchWhere(pageIndex) {
      let filterLast = _.extend({}, this.fieldsFilter);
      const { Keywords, KeywordsField } = this.searchModel;
      if (Keywords && KeywordsField) {
        filterLast[KeywordsField] = Keywords;
        delete filterLast.Keywords;
        delete filterLast.KeywordsField;
        delete filterLast.Model;
      }

      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(filterLast),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          Model: this.model || this.searchModel.Model,
          CustomerName: this.customerName || this.searchModel.CustomerName,
          QuotedTimeByStart: this.QuotedTimeByStart,
          QuotedTimeByEnd: this.QuotedTimeByEnd
        }
      );
      return data;
    },

    // 本月
    setCurrentMonth() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth();
      this.QuotedTimeByStart = new Date(y, m, 1);
      this.QuotedTimeByEnd = new Date(y, m + 1, 0);
    },

    onHideColClick(e) {
      console.log("e:", e);
    },
    async onSaveSetting(columns, pageSize) {
      localStorage.removeItem("BomMastLineSelect");
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      );
    },

    async onResetSetting() {
      localStorage.removeItem("BomMastLineSelect");
      await this.$post(this.$options.config.resetConfigURL);
    },

    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("BomMastLineSelect");
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      );
    },

    hasRes(code) {
      return _.some(this.resourceList, m => m === code);
    },
    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
    },
    // 生成发货单
    onAddStkOut(row) {
      let guid = [row.BDLineGUID];
      if (guid !== null && guid.length > 0) {
        let data = { BDLineGuids: guid };
        let order = { bomInfo: data };
        this.$store.state.visibled = false;
        this.$store.commit("setParamOrder", order);
        this.$nextTick(function() {
          this.editType = "addNew";
          this.stkOutEditVisible = true;
        });
      }
    },
    // 生成入库单
    onAddStkIn(row) {
      let guid = [row.BDLineGUID]; // 选中行的bdlineguid数组
      if (guid !== null && guid.length > 0) {
        let data = { BDLineGuids: guid };
        let order = { bomInfo: data };
        this.$store.state.visibled = false;
        this.$store.commit("setParamOrder", order);
        this.$nextTick(function() {
          this.editType = "add";
          this.stkInEditVisible = true;
        });
      }
    },
    async onExport() {
      this.loading = true;
      this.loading = await this.exportData(
        "HisOfferPrice",
        this.getSearchWhere(1),
        this.columns,
        "报价统计"
      );
    },

    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("BomMastLineSelect", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("BomMastLineSelect", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("BomMastLineSelect", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("BomMastLineSelect");
    }
  }
};
</script>
